"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "code[class*=\"language-\"]": {
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "color": "#90a4ae",
    "background": "#fafafa",
    "fontFamily": "Roboto Mono, monospace",
    "fontSize": "1em",
    "lineHeight": "1.5em",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
    "textAlign": "left",
    "whiteSpace": "pre",
    "wordSpacing": "normal",
    "wordBreak": "normal",
    "wordWrap": "normal",
    "color": "#90a4ae",
    "background": "#fafafa",
    "fontFamily": "Roboto Mono, monospace",
    "fontSize": "1em",
    "lineHeight": "1.5em",
    "MozTabSize": "4",
    "OTabSize": "4",
    "tabSize": "4",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "overflow": "auto",
    "position": "relative",
    "margin": "0.5em 0",
    "padding": "1.25em 1em"
  },
  "code[class*=\"language-\"]::-moz-selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "pre[class*=\"language-\"]::-moz-selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "code[class*=\"language-\"] ::-moz-selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "pre[class*=\"language-\"] ::-moz-selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "code[class*=\"language-\"]::selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "pre[class*=\"language-\"]::selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "code[class*=\"language-\"] ::selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  "pre[class*=\"language-\"] ::selection": {
    "background": "#cceae7",
    "color": "#263238"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "whiteSpace": "normal",
    "borderRadius": "0.2em",
    "padding": "0.1em"
  },
  ".language-css > code": {
    "color": "#f76d47"
  },
  ".language-sass > code": {
    "color": "#f76d47"
  },
  ".language-scss > code": {
    "color": "#f76d47"
  },
  "[class*=\"language-\"] .namespace": {
    "Opacity": "0.7"
  },
  "atrule": {
    "color": "#7c4dff"
  },
  "attr-name": {
    "color": "#39adb5"
  },
  "attr-value": {
    "color": "#f6a434"
  },
  "attribute": {
    "color": "#f6a434"
  },
  "boolean": {
    "color": "#7c4dff"
  },
  "builtin": {
    "color": "#39adb5"
  },
  "cdata": {
    "color": "#39adb5"
  },
  "char": {
    "color": "#39adb5"
  },
  "class": {
    "color": "#39adb5"
  },
  "class-name": {
    "color": "#6182b8"
  },
  "comment": {
    "color": "#aabfc9"
  },
  "constant": {
    "color": "#7c4dff"
  },
  "deleted": {
    "color": "#e53935"
  },
  "doctype": {
    "color": "#aabfc9"
  },
  "entity": {
    "color": "#e53935"
  },
  "function": {
    "color": "#7c4dff"
  },
  "hexcode": {
    "color": "#f76d47"
  },
  "id": {
    "color": "#7c4dff",
    "fontWeight": "bold"
  },
  "important": {
    "color": "#7c4dff",
    "fontWeight": "bold"
  },
  "inserted": {
    "color": "#39adb5"
  },
  "keyword": {
    "color": "#7c4dff"
  },
  "number": {
    "color": "#f76d47"
  },
  "operator": {
    "color": "#39adb5"
  },
  "prolog": {
    "color": "#aabfc9"
  },
  "property": {
    "color": "#39adb5"
  },
  "pseudo-class": {
    "color": "#f6a434"
  },
  "pseudo-element": {
    "color": "#f6a434"
  },
  "punctuation": {
    "color": "#39adb5"
  },
  "regex": {
    "color": "#6182b8"
  },
  "selector": {
    "color": "#e53935"
  },
  "string": {
    "color": "#f6a434"
  },
  "symbol": {
    "color": "#7c4dff"
  },
  "tag": {
    "color": "#e53935"
  },
  "unit": {
    "color": "#f76d47"
  },
  "url": {
    "color": "#e53935"
  },
  "variable": {
    "color": "#e53935"
  }
};
exports["default"] = _default;