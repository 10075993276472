"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  "code[class*=\"language-\"]": {
    "MozTabSize": "2",
    "OTabSize": "2",
    "tabSize": "2",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "whiteSpace": "pre-wrap",
    "wordWrap": "normal",
    "fontFamily": "Menlo, Monaco, \"Courier New\", monospace",
    "fontSize": "14px",
    "color": "#76d9e6",
    "textShadow": "none"
  },
  "pre[class*=\"language-\"]": {
    "MozTabSize": "2",
    "OTabSize": "2",
    "tabSize": "2",
    "WebkitHyphens": "none",
    "MozHyphens": "none",
    "msHyphens": "none",
    "hyphens": "none",
    "whiteSpace": "pre-wrap",
    "wordWrap": "normal",
    "fontFamily": "Menlo, Monaco, \"Courier New\", monospace",
    "fontSize": "14px",
    "color": "#76d9e6",
    "textShadow": "none",
    "background": "#2a2a2a",
    "padding": "15px",
    "borderRadius": "4px",
    "border": "1px solid #e1e1e8",
    "overflow": "auto",
    "position": "relative"
  },
  "pre > code[class*=\"language-\"]": {
    "fontSize": "1em"
  },
  ":not(pre) > code[class*=\"language-\"]": {
    "background": "#2a2a2a",
    "padding": "0.15em 0.2em 0.05em",
    "borderRadius": ".3em",
    "border": "0.13em solid #7a6652",
    "boxShadow": "1px 1px 0.3em -0.1em #000 inset"
  },
  "pre[class*=\"language-\"] code": {
    "whiteSpace": "pre",
    "display": "block"
  },
  "namespace": {
    "Opacity": ".7"
  },
  "comment": {
    "color": "#6f705e"
  },
  "prolog": {
    "color": "#6f705e"
  },
  "doctype": {
    "color": "#6f705e"
  },
  "cdata": {
    "color": "#6f705e"
  },
  "operator": {
    "color": "#a77afe"
  },
  "boolean": {
    "color": "#a77afe"
  },
  "number": {
    "color": "#a77afe"
  },
  "attr-name": {
    "color": "#e6d06c"
  },
  "string": {
    "color": "#e6d06c"
  },
  "entity": {
    "color": "#e6d06c",
    "cursor": "help"
  },
  "url": {
    "color": "#e6d06c"
  },
  ".language-css .token.string": {
    "color": "#e6d06c"
  },
  ".style .token.string": {
    "color": "#e6d06c"
  },
  "selector": {
    "color": "#a6e22d"
  },
  "inserted": {
    "color": "#a6e22d"
  },
  "atrule": {
    "color": "#ef3b7d"
  },
  "attr-value": {
    "color": "#ef3b7d"
  },
  "keyword": {
    "color": "#ef3b7d"
  },
  "important": {
    "color": "#ef3b7d",
    "fontWeight": "bold"
  },
  "deleted": {
    "color": "#ef3b7d"
  },
  "regex": {
    "color": "#76d9e6"
  },
  "statement": {
    "color": "#76d9e6",
    "fontWeight": "bold"
  },
  "placeholder": {
    "color": "#fff"
  },
  "variable": {
    "color": "#fff"
  },
  "bold": {
    "fontWeight": "bold"
  },
  "punctuation": {
    "color": "#bebec5"
  },
  "italic": {
    "fontStyle": "italic"
  },
  "code.language-markup": {
    "color": "#f9f9f9"
  },
  "code.language-markup .token.tag": {
    "color": "#ef3b7d"
  },
  "code.language-markup .token.attr-name": {
    "color": "#a6e22d"
  },
  "code.language-markup .token.attr-value": {
    "color": "#e6d06c"
  },
  "code.language-markup .token.style": {
    "color": "#76d9e6"
  },
  "code.language-markup .token.script": {
    "color": "#76d9e6"
  },
  "code.language-markup .token.script .token.keyword": {
    "color": "#76d9e6"
  },
  ".line-highlight.line-highlight": {
    "padding": "0",
    "background": "rgba(255, 255, 255, 0.08)"
  },
  ".line-highlight.line-highlight:before": {
    "padding": "0.2em 0.5em",
    "backgroundColor": "rgba(255, 255, 255, 0.4)",
    "color": "black",
    "height": "1em",
    "lineHeight": "1em",
    "boxShadow": "0 1px 1px rgba(255, 255, 255, 0.7)"
  },
  ".line-highlight.line-highlight[data-end]:after": {
    "padding": "0.2em 0.5em",
    "backgroundColor": "rgba(255, 255, 255, 0.4)",
    "color": "black",
    "height": "1em",
    "lineHeight": "1em",
    "boxShadow": "0 1px 1px rgba(255, 255, 255, 0.7)"
  }
};
exports["default"] = _default;